body {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.container {
  max-width: 500px;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

h1 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #333333;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333333;
  text-align: center;
}

video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  align-items: center;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555555;
}

input[type='url'],
input[type='email'],
button {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 14px;
}

button {
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

h1 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #333333;
  white-space: nowrap; /* Ensures header stays on one line */
  text-align: center; /* Centers the header */
  overflow: hidden; /* Prevents content from overflowing */
  text-overflow: ellipsis; /* Adds ellipsis (...) if content overflows */
}

p {
  font-size: 16px;
  color: #333333;
  margin-top: 20px;
}

.app {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
}

.headers {
  text-align: left;
}

.headers h1 {
  font-size: 36px;
  margin-bottom: 5px;
}

.headers h2 {
  font-size: 22px;
}

.how-to-use h2 {
  text-align: center;
}